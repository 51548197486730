@import 'tailwindcss/base';
@import './fonts.css';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    @apply font-serif text-base;
}

a:hover {
    @apply text-secondary;
}

.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-button {
    display: none !important;
}

.max-w-container {
    max-width: 1600px;
}

.contact p,
.contact h1 {
    margin-bottom: 1em;
}
